<template>
  <cart-dialog>
    <v-sheet v-if="isEmpty" height="400" max-height="80vh">
      <div class="h-full">
        <v-row
          justify="center"
          align-content="center"
          class="text-center h-full"
          no-gutters
        >
          <v-col>Your Cart is Empty.</v-col>
        </v-row>
      </div>
    </v-sheet>
    <template v-else>
      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="`item-${item.id}`"
          :title="item.name"
        >
          <template #prepend>
            <span v-if="item.image" class="mr-4">
              <v-badge
                overlap
                :content="item.quantity"
                :model-value="item.quantity > 1"
                max="10"
              >
                <v-avatar size="50" rounded="lg">
                  <media-block
                    :content="{
                      media: item.image,
                      imageOptions: {
                        fit: 'contain',
                      },
                    }"
                  />
                </v-avatar>
              </v-badge>
            </span>
          </template>
          <template #subtitle>
            {{ currency.symbol }}{{ item.total }}
            <template v-if="item.options.length">
              <span class="mx-1">|</span>
              <span v-for="(option, i) in item.options" :key="option.key">
                <template v-if="i">,</template> {{ option.value }}
              </span>
            </template>
          </template>
        </v-list-item>
      </v-list>
      <v-divider />
      <div class="pa-2">
        <v-btn
          @click="toCheckout"
          color="primary"
          variant="flat"
          size="large"
          block
        >
          Checkout
        </v-btn>
      </div>
    </template>
  </cart-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CartDialog from "./CartDialog.vue";

export default defineComponent({
  name: "InstantCart",
  components: { CartDialog },
  setup() {
    const { currency } = useCurrency();
    const { items, loading, itemsCount, isEmpty, loadOnce, toCheckout } =
      useCart();

    onMounted(loadOnce);

    return {
      currency,
      items,
      loading,
      itemsCount,
      isEmpty,
      toCheckout,
    };
  },
});
</script>

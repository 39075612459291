export const useInstantCart = () => {
  const state = useState("instant-cart", () => ({
    open: false,
  }));

  const open = () => (state.value.open = true);
  const close = () => (state.value.open = false);
  const toggle = () => (state.value.open = !state.value.open);

  return {
    state,
    open,
    close,
    toggle,
  };
};

<template>
  <notifications
    :group="group"
    :position="position"
    :animation-name="animationName"
  >
    <template #body="props">
      <v-alert
        v-bind="{ ...$props, ...props.item.data }"
        :colorr="color || props.item.color || props.item.type"
        class="ma-2"
      >
        <div class="text-subtitle-2">{{ props.item.title }}</div>
        <div class="caption" v-html="props.item.text"></div>
      </v-alert>
    </template>
  </notifications>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { VAlert } from "vuetify/components";

export default defineComponent({
  props: {
    ...VAlert.props,
    group: { type: String },
    dismissible: { type: Boolean, default: true },
    icon: { type: [String, Boolean], default: false },
    position: { type: String },
    type: { type: String },
    // position: {},
    border: {},
    animationName: {},
  },
});
</script>

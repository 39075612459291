<template>
  <v-app id="app" full-height>
    <no-js />

    <slot></slot>

    <instant-cart />

    <app-notifications
      position="top right"
      type="info"
      border
      dark
      dense
      closable
    />
    <app-notifications
      group="app"
      position="top center"
      type="info"
      border="top"
      dark
      dense
    />
    <app-notifications
      group="network"
      position="bottom center"
      type="warning"
      icon="cloud_off"
      closable
    />
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import colors from "vuetify/lib/util/colors.mjs";
import NoJs from "@/components/elements/NoJs.vue";
import AppNotifications from "~/components/elements/AppNotifications.vue";
import InstantCart from "~~/components/elements/cart/InstantCart";

export default defineComponent({
  components: {
    NoJs,
    AppNotifications,
    InstantCart,
  },
  setup() {
    const config = useAppConfig();

    useServerSeoMeta({
      title: config.app.name,
      description: config.app.description,
      ogTitle: config.app.name,
      ogDescription: config.app.description,
      ogSiteName: config.app.url,
      ogImage: config.app.img,
      ogUrl: config.app.url,
      themeColor: colors.orange.base,
      twitterSite: config.app.url,
      twitterImage: config.app.img,
    });
  },
});
</script>
